import {
  Fingerprint,
  Infinity,
  Languages,
  LayoutDashboard,
  Network,
  ShieldPlus,
} from "lucide-react";
import {
  createElement,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { Definition } from "sutro-common";
import { pick } from "sutro-common/collection-helpers/pick";
import { useShallow } from "zustand/react/shallow";

import { Button } from "~/components/ui/button";
import { Tabs, TabsList, TabsTrigger } from "~/components/ui/tabs";

import { useStudio } from "~/lib/hooks/use-studio";
import { JsonEditor } from "./json-editor";

enum DefinitionEditable {
  DATA_TYPES = "dataTypes",
  ACTIONS = "actions",
  APP_META = "appMeta",
  PAGE_CONTENTS_CONFIG = "pageContentsConfig",
  SLANG = "slang",
  REMAINING = "remainingDefinition",
}

const useJsonState = (initial: unknown) =>
  useState(JSON.stringify(initial, null, 2));

export const PostPluginDefinitionPlayground = () => {
  const postPluginDefinition = useStudio((s) => s.postPluginDefinition);
  return <DefinitionPlayground definition={postPluginDefinition ?? null} />;
};

export const DefinitionPlayground = ({
  definition,
}: {
  definition?: Definition | null;
}) => {
  const { workingDefinition, setWorkingDefinitionAndValidate } = useStudio(
    useShallow(pick("workingDefinition", "setWorkingDefinitionAndValidate"))
  );

  const setDefinition: typeof setWorkingDefinitionAndValidate = useCallback(
    () =>
      definition !== undefined ? () => {} : setWorkingDefinitionAndValidate,
    [definition, setWorkingDefinitionAndValidate]
  );

  const {
    dataTypes: _dataTypes,
    actions: _actions,
    appMeta: _appMeta,
    pageContentsConfig: _pageContentsConfig,
    slang: _slang,
    ..._rest
  } = definition ?? workingDefinition;

  const [dataTypes, setDataTypes] = useState(
    JSON.stringify(_dataTypes, null, 2)
  );
  const [actions, setActions] = useJsonState(_actions);
  const [appMeta, setAppMeta] = useJsonState(_appMeta);
  const [pageContentsConfig, setPageContentsConfig] =
    useJsonState(_pageContentsConfig);
  const [slang, setSlang] = useState<string>(_slang ?? "");
  const [remainingDefinition, setRemainingDefinition] = useJsonState(_rest);

  const [currentEditableDisplayed, setCurrentEditableDisplayed] = useState(
    DefinitionEditable.DATA_TYPES
  );
  const stateMap: Record<
    DefinitionEditable,
    {
      state: string;
      updateLocalState: Dispatch<SetStateAction<string>>;
      language: "json" | "slang";
      icon: React.ComponentType;
    }
  > = {
    [DefinitionEditable.DATA_TYPES]: {
      state: dataTypes,
      updateLocalState: setDataTypes,
      language: "json",
      icon: Network,
    },
    [DefinitionEditable.ACTIONS]: {
      state: actions,
      updateLocalState: setActions,
      language: "json",
      icon: ShieldPlus,
    },
    [DefinitionEditable.APP_META]: {
      state: appMeta,
      updateLocalState: setAppMeta,
      language: "json",
      icon: Fingerprint,
    },
    [DefinitionEditable.PAGE_CONTENTS_CONFIG]: {
      state: pageContentsConfig,
      updateLocalState: setPageContentsConfig,
      language: "json",
      icon: LayoutDashboard,
    },
    [DefinitionEditable.SLANG]: {
      state: slang,
      updateLocalState: setSlang,
      language: "slang",
      icon: Languages,
    },
    [DefinitionEditable.REMAINING]: {
      state: remainingDefinition,
      updateLocalState: setRemainingDefinition,
      language: "json",
      icon: Infinity,
    },
  };

  const saveEdits = useCallback(() => {
    setDefinition({
      ...JSON.parse(remainingDefinition ?? "{}"),
      appMeta: JSON.parse(appMeta),
      dataTypes: JSON.parse(dataTypes),
      actions: JSON.parse(actions),
      pageContentsConfig: JSON.parse(pageContentsConfig),
      slang: slang.trim().length > 0 ? slang.trim() : undefined,
    });
  }, [
    setDefinition,
    remainingDefinition,
    appMeta,
    dataTypes,
    actions,
    pageContentsConfig,
    slang,
  ]);

  if (definition === null) {
    return (
      <span>
        Definition is <code>null</code>.
      </span>
    );
  }

  return (
    <div className="flex size-full flex-col items-center overflow-hidden">
      <Tabs defaultValue="definition" className="flex flex-col">
        <TabsList>
          {Object.entries(stateMap).map(([key, value]) => {
            return (
              <TabsTrigger
                key={key}
                value={key}
                onClick={() =>
                  setCurrentEditableDisplayed(key as DefinitionEditable)
                }
              >
                <div className="mr-2">{createElement(value.icon)}</div>
                {key}
              </TabsTrigger>
            );
          })}
        </TabsList>
      </Tabs>

      <div className="w-full flex-1 overflow-auto p-4">
        <JsonEditor
          text={stateMap[currentEditableDisplayed].state}
          onEdit={stateMap[currentEditableDisplayed].updateLocalState}
          language={stateMap[currentEditableDisplayed].language}
          className="flex size-full flex-1"
        />
      </div>

      <Button onClick={saveEdits}>Save</Button>
    </div>
  );
};
