import { create } from "zustand";

export interface DevOptions {
  shouldGetPostPluginDefinition: boolean;
}

export interface DevOptionsStore {
  devOptions: DevOptions;
  setDevOptions: (newOptions: Partial<DevOptions>) => void;
}

export const useDevOptions = create<DevOptionsStore>()((set) => {
  const setDevOptions = (newOptions: Partial<DevOptions>) => {
    set(({ devOptions: options }) => ({
      devOptions: { ...options, ...newOptions },
    }));
  };
  return {
    devOptions: {
      shouldGetPostPluginDefinition: false,
    },
    setDevOptions,
  };
});
